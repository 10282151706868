<script setup lang="ts">
interface SearchBarProps {
  alignOffset?: number
  sideOffset?: number
  refrehsOnConfirm?: boolean
  pageType?: string
}
withDefaults(defineProps<SearchBarProps>(), {
  alignOffset: 5,
  sideOffset: 0,
  refrehsOnConfirm: false,
  pageType: '',
})

const filterConditionStore = useFilterConditionsStore()
const showPopup = ref(false)
const showSearchPopup = ref(false)
const { t } = useI18n()
const { searchInput, clearSearch, watchSearchInput } = useSearchAttr()
watchSearchInput()

const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})
function confirmAttr() {
  showPopup.value = false
  showSearchPopup.value = false
}

function confirmCity() {
  showPopup.value = false
  showSearchPopup.value = false
}

function handleClearSearch() {
  clearSearch()
  navigateToList({ replace: true })
}
</script>

<template>
  <div flex flex-nowrap>
    <BaseAZPopover v-model:open="showPopup" :show-close-icon="false" align="start" :align-offset="alignOffset" :side-offset="sideOffset">
      <template #reference>
        <div class="flex items-center">
          <div mr-30px min-w-220px flex items-center justify-center px @click.stop="showPopup = !showPopup">
            <div relative mb-1px flex flex-nowrap items-center>
              <div truncate text-22px text-black font-600>
                {{ selectCityName }}
              </div>
              <div top="50%" right="-6" class="translate-y-[-8px]" absolute h-18px w-18px flex items-center justify-center rounded-full bg-black>
                <ElIcon><ElIconArrowDownBold color="white" /></ElIcon>
              </div>
            </div>
          </div>
        </div>
      </template>
      <DesktopSearchCity @confirm="confirmCity"></DesktopSearchCity>
    </BaseAZPopover>
    <div bg="[#F3F3F3]" w-full flex flex-nowrap rounded-full px-2 py-2>
      <BaseAZPopover v-model:open="showSearchPopup" w-full :show-close-icon="false" align="start" :align-offset="-8" :side-offset="12">
        <template #reference>
          <div class="h-30px flex items-center bg-[#F3F3F3]">
            <NuxtIcon name="my-icon:search" m="l-4" text="xl #8D8D8D" class="align-middle"></NuxtIcon>
            <input v-model="searchInput" flex-1 width="100%" class="2xl:text-base h-11 px-4 font-300 leading-9" text="base #000" :placeholder="t('key.search.attrs.placeholder')" @click.stop="showSearchPopup = !showSearchPopup" />
            <ElIcon v-if="!!searchInput" size="21" color="#aaa" @click.stop="handleClearSearch">
              <ElIconCircleCloseFilled></ElIconCircleCloseFilled>
            </ElIcon>
          </div>
        </template>
        <DesktopSearchAttrs @confirm="confirmAttr"></DesktopSearchAttrs>
      </BaseAZPopover>
    </div>
  </div>
</template>
